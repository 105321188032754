import {Field, Form, Formik} from 'formik'
import React, {ChangeEvent, useEffect, useState} from 'react'
import {createFormData, useCallApi} from '../../modules/utils'
import {useAuth} from '../../modules/auth'
import {ToastContainer, toast} from 'react-toastify'
import {KTSVG} from '../../../_metronic/helpers'
import {Loading} from '../Loading'
import { ModalAddCategorie } from '../categorie/ModalAddCategorie'
import AsyncSelectField from '../AsyncSelectField'
import { Obj } from '@popperjs/core'
import axios from 'axios'
import { useFormik } from 'formik';
import { NewPurchase } from '../purchase/NewPurchase'

type Props = {
  loadProduct?:()=>void
}
export const ModalAddProduct: React.FC<Props> = ({loadProduct}) => {
  const {getProducts} = useAuth()
  const callApi = useCallApi()
  const [loading, setLoading] = useState(false)
  
  const initialValues = {
    name: '',
    code: '',
    product_category_id: '',
    brand_id: '',
    supplier_id:'',
    product_price: 0,
    notes: '',
    status: true,
    price_purchase: 0,
    nbrpoints: 0,
    tva:0
  }
  const [image, setImage] = useState<File | any>([])

  const [filess, setFiles] = useState<string>()
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: values => {
    },
});
  const handelSumit = async (values: any) => {
    // setLoading(true)
    const dataTosend = {
      barcode_symbol: values.barcode_symbol,
      name: values.name,
      code: values.code,
      product_category_id: values.product_category_id,
      product_price: formik.values.product_price,
      notes: values.notes,
      active: values.status ? 1 : 0,
      price_purchase: formik.values.price_purchase,
      nbrpoints: values.nbrpoints,
      brand_id:values.brand_id ,
      supplier_id:values.supplier_id,
      tva:values.tva
    }

    const formData = createFormData(dataTosend, image)

    try {
      const dat = await callApi({
        route: `api/products`,
        method: 'POST',
        body: formData,
      })
      toast.success('ajoute avec succes')
      // loadProduct && loadProduct()
       getProducts()
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }
  
  const loadOptions = async (inputValue:string,entity:string) => {
    if (!inputValue) {
      return [];
    }

    try {
    
      const {data}  = await callApi({
              route: 'api/'+entity+'?name='+inputValue,
              method: 'GET',
      });


      return data.data.map((category:any) => ({
        value: category.id,
        label: category.attributes.name,
      }));
    } catch (error) {
      console.error('Error fetching categories:', error);
      return [];
    }
  }

  useEffect(() => {
    const newPrice:number = formik.values?.price_purchase*1+ (formik.values?.price_purchase * 0.4);
    console.log(formik.values?.price_purchase,formik.values?.price_purchase,(formik.values?.price_purchase * 0.4));
    
    formik.setFieldValue('product_price', newPrice);
     
  }, [formik.values?.price_purchase])
  
  return (
    <>
                                 <ModalAddCategorie add={true}/>

      <div
        className='modal fade'
        id='modaladdproduct'
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg modal-dialog-centered' role='document' >
          <Formik
            initialValues={initialValues}
            onSubmit={(values: any, {resetForm}) => {
              handelSumit(values)
                resetForm()
                setFiles("")
            }}
          
            
            
          >
            {() => (
              <Form className='modal-content' >
                <div className='modal-content'>
                  <div className='modal-header '>
                    <h1 className='modal-title fs-5 text-primary' id='exampleModalLabel'>
                      Ajouter de produit:
                    </h1>
                    <button
                      type='button'
                      className='btn-close text-white'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    ></button>
                  </div>
                  <div className='modal-body' style={{height: '392px', overflowY: 'scroll'}}>
                    <form>
                    <label htmlFor='exampleInputEmail1' className='fw-bold'>
                        Photo :
                      </label>
                      <div className='form-group mb-5  '>
                        <div
                          className='image-input image-input-outline'
                          data-kt-image-input='true'
                          style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}
                        >
                          <div
                            id='image'
                            className='image-input-wrapper w-125px h-125px'
                            style={{
                              backgroundImage: filess == '' ? "url('')" : 'url(' + filess + ')',
                            }}
                          ></div>
                          <label
                            className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action='change'
                            data-bs-toggle='tooltip'
                            data-bs-dismiss='click'
                            title='Change avatar'
                          >
                            <i className='bi bi-pencil-fill fs-7'></i>

                            <Field
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                // const reader = new FileReader();
                                // reader.onload=function(e){
                                //   setUrl(e.target?.result)
                                // }
                                // reader.readAsDataURL(e.target.files[0])
                                if (e.target.files && e.target.files[0]) {
                                  setFiles(URL.createObjectURL(e.target.files[0]))
                                  setImage(Array.from(e.target.files))
                                }
                              }}
                              type='file'
                              name='image'
                              accept='.png, .jpg, .jpeg'
                            />
                            <Field type='hidden' name='avatar_remove' />
                          </label>

                          <span
                            className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action='cancel'
                            data-bs-toggle='tooltip'
                            data-bs-dismiss='click'
                            title='Cancel avatar'
                          >
                            <i className='bi bi-x fs-2'></i>
                          </span>
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Nom :
                            </label>
                            <Field
                              name='name'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Catégorie :
                            </label>
                            <div className='row'>
                              <div className='col-10'>

      
           <Field
            name="product_category_id"
            as={AsyncSelectField}
            entity="product-categories"
            loadOptions={loadOptions}
          />
                              </div>
                              <div className='col-2'>
                                <button
                                 type='button'
                                 data-bs-toggle='modal'
                                 data-bs-target={'#ModalAddCategorie'}
                          
                                  className='btn btn-icon btn-bg-light btn-active-color-primary rounded-5 btn-sm me-1'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen035.svg'
                                    className='svg-icon-2'
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <div className='col'>
                        <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Prix d'achat :
                            </label>
                            <Field
                              name='price_purchase'
                              type='number'
                              onChange={(e:any)=>{
        
                                formik.setFieldValue('price_purchase',e.target.value);
                              }}
                              value={formik.values.price_purchase}
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                          {' '}
                         
                        </div>
                        <div className='col'>
                          {' '}
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Prix de vente :
                            </label>
                            <Field
                             value={formik.values.product_price}
                              name='product_price'
                              type='number'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                       
                        
                      </div>
                      <div className="row mb-4">
                        <div className="col">
                        <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              TVA :
                            </label>
                            <Field
                              name='tva'
                              type='number'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div> 
                        </div>
                       <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Nombre des points :
                            </label>
                            <Field
                              name='nbrpoints'
                              type='number'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row  '>
                        <div className='col'>
                          <div className='form-group mb-4 fw-bold'>
                            <label htmlFor='exampleInputEmail1'> Code bare :</label>
                            <Field
                              name='code'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group mb-4 fw-bold'>
                            <label htmlFor='exampleInputEmail1'> Référence
                            :</label>
                            <Field
                              name='barcode_symbol'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group mb-4 fw-bold'>
                            <label htmlFor='exampleInputEmail1'>Description :</label>
                            <Field
                              name='notes'
                              as={'textarea'}
                              rows={2}
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row  '>
                        <div className='col'>
                          <div className='form-group mb-4 fw-bold'>
                            <label htmlFor='exampleInputEmail1'> Fournisseur :</label>
                            <Field
            name="supplier_id"
            as={AsyncSelectField}
                        entity="suppliers"
            loadOptions={loadOptions}
          />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group mb-4 fw-bold'>
                            <label htmlFor='exampleInputEmail1'>Marque :</label>
                            <Field
            name="brand_id"
            as={AsyncSelectField}
                        entity="brands"
            loadOptions={loadOptions}
          />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          <div className='form-group d-flex fw-bold'>
                            {' '}
                            <label htmlFor='exampleInputEmail1'>Statut :</label>
                            <div className='form-check form-switch'>
                              <Field
                                className='form-check-input'
                                type={'checkbox'}
                                name={'status'}
                                role='switch'
                                //   onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                //     const { checked } = e.target;
                                //     setchejed(checked)
                                //   }}
                                id='flexSwitchCheckDefault'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Annuler
                    </button>
                    {loading ? (
                      <button type='button' className='btn btn-primary'>
                        <Loading />
                      </button>
                    ) : (
                      <button type='submit' className='btn btn-primary'>
                        valider
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
