import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from '../../modules/auth';
import { useCallApi } from '../../modules/utils';
import { Loading } from '../Loading';
import { KTSVG } from '../../../_metronic/helpers';

export const ModalAddClient = () => {
  const {  setClients } = useAuth(); // Assuming useAuth provides setClients for updating clients
  const callApi = useCallApi();
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState<string[]>([])  ;

  const initialValues = {
    email: '',
    name: '',
    adresse: '',
    ice: '',
    phone: '',
  };

  const handleAddService = () => {
    setServices([...services, '']);
  };

  const handleRemoveService = (index: number) => {
    setServices(services.filter((_, i) => i !== index));
  };

  const handleServiceChange = (index: number, value: string) => {
    const newServices = [...services];
    newServices[index] = value;
    setServices(newServices);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const dataToSend = {
      ...values,
      services,
    };

    try {
     

      const updatedClients = await callApi({ route: 'api/customers', method: 'GET' });
      setClients(updatedClients);

      setLoading(false);
      const data = await callApi({
        route: 'api/customers',
        method: 'POST',
        body: dataToSend, 
      });
      toast.success('Ajouté avec succès');

     
    } catch (error: any) {
      toast.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className='modal fade'
        id='ModalAddClient'
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm();
            }}
          >
            {() => (
              <Form className='modal-content'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h1 className='modal-title fs-5 text-primary' id='exampleModalLabel'>
                      Nouveau Client
                    </h1>
                    <button
                      type='button'
                      className='btn-close text-white'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    ></button>
                  </div>
                  <div className='modal-body'>
                    <div className='row mb-4'>
                      <div className='col'>
                        <div className='form-group'>
                          <label htmlFor='name' className='fw-bold'>
                            Nom & prénom :
                          </label>
                          <Field name='name' type='text' className='form-control' id='name' />
                        </div>
                      </div>
                      <div className='col'>
                        <div className='form-group'>
                          <label htmlFor='email' className='fw-bold'>
                            Email:
                          </label>
                          <Field name='email' type='email' className='form-control' id='email' />
                        </div>
                      </div>
                    </div>
                    <div className='row mb-4'>
                      <div className='col'>
                        <div className='form-group'>
                          <label htmlFor='adresse' className='fw-bold'>
                            Adresse :
                          </label>
                          <Field name='adresse' type='text' className='form-control' id='adresse' />
                        </div>
                      </div>
                      <div className='col'>
                        <div className='form-group'>
                          <label htmlFor='phone' className='fw-bold'>
                            Tel :
                          </label>
                          <Field name='phone' type='phone' className='form-control' id='phone' />
                        </div>
                      </div>
                    </div>
                    <div className='row mb-4'>
                      <div className='col'>
                        <div className='form-group'>
                          <label htmlFor='ice' className='fw-bold'>
                            ICE :
                          </label>
                          <Field name='ice' type='text' className='form-control' id='ice' />
                        </div>
                      </div>
                      <div className='col'>
                        <div className='form-group'>
                          <label htmlFor='services' className='fw-bold'>
                            Services :
                          </label>
                          {services.map((service, index) => (
                            <div className='d-flex mb-2' key={index}>
                              <Field
                                name={`service-${index}`}
                                type='text'
                                className='form-control me-2'
                                value={service}
                                onChange={(e: any) => handleServiceChange(index, e.target.value)}
                              />
                              <button
                                type='button'
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                onClick={() => handleRemoveService(index)}
                              >
                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 ' />
                              </button>
                            </div>
                          ))}
                          <button type='button' className='btn btn-secondary' onClick={handleAddService}>
                            Ajouter
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Annuler
                    </button>
                    {loading ? (
                      <button type='button' className='btn btn-primary'>
                        <Loading />
                      </button>
                    ) : (
                      <button type='submit' className='btn btn-primary'>
                        Valider
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
