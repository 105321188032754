import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from '../../modules/auth';
import { useCallApi } from '../../modules/utils';
import { Loading } from '../Loading';
import { KTSVG } from '../../../_metronic/helpers';

type Props = {
  data: any;
};

export const ModalUpdateClient: React.FC<Props> = ({ data }) => {
  const callApi = useCallApi();
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState<string[]>(data?.attributes?.services || []);

  const initialValues = {
    email: data?.attributes?.email || '',
    name: data?.attributes?.name || '',
    adresse: data?.attributes?.adresse || '',
    phone: data?.attributes?.phone || '',
    ice: data?.attributes?.ice || '',
  };

  
  const addService = () => {
    setServices([...services, '']); // Add an empty string to services array
  };

  const removeService = (index: number) => {
    const updatedServices = services.filter((_, i) => i !== index);
    setServices(updatedServices);
  };

  const handleServiceChange = (index: number, value: string) => {
    const updatedServices = [...services];
    updatedServices[index] = value; // Update the specific service
    setServices(updatedServices);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      await callApi({
        route: `api/customers/${data?.id}`, // Add client ID to route if updating
        method: 'PUT',
        body: { ...values, services: services }, // Send services as JSON
      });
      toast.success('Modifié avec succès');
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Erreur lors de la modification');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className='modal fade'
        id={'ModalUpdateClient' + data.id}
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm();
            }}
          >
            {() => (
              <Form className='modal-content'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                      Modifier Client
                    </h1>
                    <button
                      type='button'
                      className='btn-close text-white'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    ></button>
                  </div>
                  <div className='modal-body'>
                    <div className='row mb-4'>
                      <div className='col'>
                        <label htmlFor='name' className='fw-bold'>Nom & Prénom:</label>
                        <Field name='name' type='text' className='form-control' />
                      </div>
                      <div className='col'>
                        <label htmlFor='email' className='fw-bold'>Email:</label>
                        <Field name='email' type='email' className='form-control' />
                      </div>
                    </div>
                    <div className='row mb-4'>
                      <div className='col'>
                        <label htmlFor='adresse' className='fw-bold'>Adresse:</label>
                        <Field name='adresse' type='text' className='form-control' />
                      </div>
                      <div className='col'>
                        <label htmlFor='phone' className='fw-bold'>Tel:</label>
                        <Field name='phone' type='text' className='form-control' />
                      </div>
                    </div>
                    <div className='row mb-4'>
                      <div className='col'>
                        <label htmlFor='ice' className='fw-bold'>ICE:</label>
                        <Field name='ice' type='text' className='form-control' />
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label className='fw-bold'>Services:</label>
                      {services?.map((service, index) => (
                        <div key={index} className='input-group mb-2'>
                          <input
                            type='text'
                            className='form-control'
                            value={service}
                            onChange={(e) => handleServiceChange(index, e.target.value)}
                            placeholder='Enter service'
                          />
                          <button
                            type='button'
                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                            onClick={() => removeService(index)}
                          >
                                          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 ' />

                          </button>
                        </div>
                      ))}
                      <button type='button' className='btn btn-secondary' onClick={addService}>
                        Ajouter
                      </button>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Annuler
                    </button>
                    <button type='submit' className='btn btn-primary'>
                      {loading ? <Loading /> : 'Valider'}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
