import React, {useEffect, useState} from 'react'

import {ToastContainer, toast} from 'react-toastify'

import { ItemClient } from './ItemClient'
import { KTSVG } from '../../../_metronic/helpers'
import SearchComponent from '../SearchComponent'
import { ModalAddClient } from './ModalAddClient'
import { useCallApi } from '../../modules/utils'

type Props = {
  states: any
  // deleteVille:(id:number)=>void
}
export const ListClients: React.FC<Props> = ({states}) => {
  const [utilisateurs, setUtilisateurs] = useState<any[]>([])
  console.log(utilisateurs)

  const [loading, setLoading] = useState(true)
  const [itemsSelected, setItemsSelected] = useState<number>(0)
  const [checkedProductIds, setCheckedProductIds] = useState<any>([]) // State to store checked product IDs
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filterProduct, setFilterProduct] = useState<any>([])

  const callApi = useCallApi()
  const getProducts = async () => {
    try {
      const {data} = await callApi({
        route: 'api/customers?page[size]=10&page[number]=' + states.currentPage,
        method: 'GET',
      })

      const initialProducts = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setUtilisateurs(initialProducts)
      states.setTotalItems(data.meta.total)
      states.setTotalPages(Math.ceil(states.totalItems / 100))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const updatedProducts = filterProduct.map((product: any) => ({
      ...product,
      checked,
    }))
    if (checked) {
      const checkedProductIds = updatedProducts.map((product: any) => product.id)
      // Store the checkedProductIds in your table or wherever you need
      setItemsSelected(checkedProductIds.length)
    } else {
      setItemsSelected(0)
    }
    const checkedIds = updatedProducts
      .filter((product: any) => product.checked)
      .map((product: any) => product.id)
    setCheckedProductIds(checkedIds)
    setFilterProduct(updatedProducts)
  }

  const handleProductCheckboxChange =
    (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const updatedProducts = filterProduct.map((product: any) =>
        product.id === productId ? {...product, checked} : product
      )
      setFilterProduct(updatedProducts)
      const checkedIds = updatedProducts
        .filter((product: any) => product.checked)
        .map((product: any) => product.id)
      setCheckedProductIds(checkedIds)
      setItemsSelected(checkedIds.length)
    }
  const deleteProduct = async (ids: number[]) => {
    if (window.confirm('Voulez-vous vraiment supprimer ce client !')) {
      ids.map(async (id: number) => {
      
        const filteredposts = utilisateurs.filter((product: any) => product.id !== id)
        setFilterProduct(filteredposts)
        toast.success('Client   supprimé')
        const {data} = await callApi({
          route: 'api/customers/' + id,
          method: 'DELETE',
        })  })
      }
  
  }
  const getUtilisateurByName = async () => {
    try {
      const {data} = await callApi({  
        route: `api/customers?page[size]=10&page[number]=1&filter[search]=${searchTerm}`,
        method: 'GET',
      })

      const initialProducts = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setUtilisateurs(initialProducts)
      states.setTotalItems(data.meta.total)
      states.setTotalPages(Math.ceil(data.meta.total / data.meta.per_page))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  useEffect(() => {
    getProducts()
  }, [states.currentPage])
  useEffect(() => {
    setFilterProduct(utilisateurs)
  }, [utilisateurs])

  useEffect(() => {
    getUtilisateurByName()
  }, [searchTerm])

  return (
    <>
    <ToastContainer/>
      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Clients
              <SearchComponent
                placeholder={'Recherche Client'}
                searchQuery={[searchTerm, setSearchTerm]}
              />
            </span>
          </h3>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a user'
            >
              <>
              <button
                  type='button'
                  className='btn btn-sm btn-primary me-3'
                  title='actualiser'
                  onClick={()=> getUtilisateurByName()}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr029.svg' className='svg-icon-2' />
                 
                </button>
              </>
              {itemsSelected != 0 ? (
                <div className='d-flex justify-content-end align-items-center'>
                  <div className='fw-bolder me-5'>
                    <span className='me-2'>{itemsSelected}</span> Selectionnées
                  </div>
                  <button
                    type='button'
                    className='btn  btn-sm btn-danger'
                    onClick={() => {
                      deleteProduct(checkedProductIds)
                    }}
                  >
                    Supprimer les sélectionnés
                  </button>
                </div>
              ) : (
                <>

                  {' '}
                  <button
                    type='button'
                    className='btn btn-sm btn-primary '
                    data-bs-toggle='modal'
                    data-bs-target='#ModalAddClient'
                  >
                    <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
                    Nouveau Client
                  </button>
                  <ModalAddClient />
                </>
              )}
            </div>
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>
                      <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filterProduct.every((product: any) => product.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div>
                    </th>
                    <th className='min-w-150px '>Nom</th>
                    {/* <th className="min-w-150px">Pays</th> */}
                    <th className='min-w-150px '>Email</th>
                    <th className='min-w-150px text-center'>Tel</th>
                    <th className='min-w-150px text-center'>ICE</th>

                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {filterProduct.map((product: any, index: any) => {
                    return (
                      <ItemClient
                        // deleteProduct={deleteProduct}
                        key={index}
                        data={product}
                        handleProductCheckboxChange={handleProductCheckboxChange}
                      />
                    )
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
