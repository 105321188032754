import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal } from '@mui/material';
import { useAuth } from '../../modules/auth';
import { useCallApi } from '../../modules/utils';
import { toast } from 'react-toastify';
import { FaRegTrashAlt } from 'react-icons/fa';

export default function FormBN() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { clients } = useAuth();
  const [startDate, setStartDate] =useState<string>('');
  const [endDate, setEndDate] =useState<string>('');
  const [date, setDate] =useState<string>('');


  const [client, setClient] = useState<any>('');
  const [service, setService] = useState<any>('');
  const [services, setServices] = useState<any>([]);

  const [sales, setSales] = useState<any>([]);

  const [filterSales, setFilterSales] = useState<any>([]);

  const callApi = useCallApi()

  const handleSubmit=async()=>{
    if(client=="" || startDate=="" || service==""){
      toast.warning("veillez selectionner tous les champs!")

    }
    try {
      const data = await callApi({
        route:`api/pdf_bn_client/${date}/${client}/${service}`,
        method: 'POST',
        body:{sales:filterSales}
      
      })
      // const dataS = await callApi({
      //   route:`api/invoices`,
      //   method: 'POST',
      //   body:filterSales
      
      // })
      if (data && data.data?.data.sale_pdf_url) {
        toast.success("facture generée")
        window.open(data.data?.data.sale_pdf_url, '_blank');
    } 
    } catch (error) {
      toast.error("BN introuvable!")

    }
  }
  const getSales=async()=>{
    const {data} = await callApi({
      route: `api/sale_invoiceds?filter[customer_id]=${client}
      &filter[startDate]=${startDate}
      &filter[endDate]=${endDate}&filter[service]=${service}`,
    
      method: 'GET',
    })
    setSales(data.data)
  
  }
  useEffect(() => {
    if(startDate!='' && endDate!='' && client!='' && service!=''){
      getSales()
    }
  }, [startDate,endDate,client,service])
  useEffect(() => {
    setFilterSales(sales)

  }, [sales])
  
  useEffect(() => {
    const selectedClient = clients.find((c: any) => c.id == client);
    const sc = selectedClient?.attributes?.services;
    
    if (sc) {
      setServices(Array.isArray(sc) ? sc : JSON.parse(sc));
    } else {
      setServices([]); 
    }
  }, [client,clients])
  
  return (
    <div>
      <TriggerButton type="button" onClick={handleOpen}>
        Génération de facture
      </TriggerButton>
      <ModalB
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent>
          <h2 id="unstyled-modal-title" className="modal-title">
            Génération de facture :
          </h2>
          <div className=" modal-description">
            <div className="row mb-2">
            <div className='col me-3'>
                  <label className='fs-7 text-gray-500 fw-bold'>Date Facture(impression):</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
            </div>
<div className="row">

  <div className='col me-3'>
                  <label className='fs-7 text-gray-500 fw-bold'>Date départ:</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className='col me-3'>
                  <label className='fs-7 text-gray-500 fw-bold'>Date arrivé:</label>
                  <input
                    type='date'
                    min={startDate}
                    className='form-control form-control-solid'
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div className='col'>
                  <label className='fs-7 text-gray-500 fw-bold mb-1'>Client:</label>
                  <select
                    className='form-select form-select-solid'
                    onChange={(e) => setClient(e.target.value)}
                  >
                    <option value=''>choisir..</option>
                    {clients &&
                      clients.map((client: any, index: number) => (
                        <option key={index} value={client.id}>
                          {client.attributes.name}
                        </option>
                      ))}
                  </select>
                </div> 
                <div className='col'>
                  <label className='fs-7 text-gray-500 fw-bold mb-1'>Service:</label>
                  <select
                    className='form-select form-select-solid'
                    onChange={(e) => setService(e.target.value)}
                  >
                    <option value=''>choisir..</option>
                    {services && services?.map((service:string,index:number)=>{
                      return <option key={index} value={service}>
                      {service}
                    </option>
                    })}
                       
                      
                  </select>
                </div>   
</div>
<div className="row ">
<div>
      <ul id="orderitems" className="list-group mt-2">
        <li className="list-group-item active ">
          <div className="row">
            <div className="col-2">Numéro</div>
            <div className="col-2">Date</div>
            <div className="col-3 text-center">Client</div>
            <div className="col-2 text-center">Total</div>
            <div className="col-3 text-center"></div>

          </div>
        </li>
        {filterSales.map((sale_item: any, index: number) => {
          return (
            <li key={index} id="288" className="list-group-item">
              <div className="row">
                <div id="order-item-name" className="col-2">
             {sale_item.attributes.num}
                </div>
                <div id="order-item-name" className="col-2">
                 {sale_item.attributes.date.slice(0, 10)}
                </div>
                <div className="col-3 text-center">
                  <span id="order-item-price">{sale_item.attributes.customer_name} </span>
                </div>
               
                <div className="col-2 text-center">
                  <span id="order-item-price">{sale_item.attributes.grand_total} DH</span>
                </div>
                <div className="col-3 text-center">
                <FaRegTrashAlt 
                onClick={() => setFilterSales(filterSales.filter((item: any) => item.id !== sale_item.id))} 
                style={{ fill: 'red', fontSize: 'initial', cursor: 'pointer' }} 
              />


                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
</div>
<div className="row d-flex justify-content-center">
<button
                      type='button'
                      className='btn fw-bold btn-info p-3 m-5'
                      onClick={handleSubmit}
                    >
                      Télécharger
                    </button>
</div>
                 </div>
        </ModalContent>
      </ModalB>
    </div>
  );
}

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'base-Backdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const ModalB = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

const TriggerButton = styled('button')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
    background: #009ef7;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: white;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  

    &:active {
      background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
      outline: none;
    }
  `,
);
